<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              :disabled="isNew"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list dense rounded nav>
            <v-list-item link class="px-4" @click.stop="deleteDialog">
              <v-list-item-icon>
                <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form
          v-model="formValid" ref="form"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field type="text"
                          hide-details
                          filled
                          label="Адреса будинку"
                          v-model="buildingAddress"
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <AddressElementSelect v-model="cityId"
                                  label="Населений пункт" filled req
                                  select_type="city"
            />
          </v-col>
          <v-col cols="12" md="12">
            <AddressElementSelect :parent_id="cityId" v-model="streetId"
                                  label="Вулиця(-ки)" filled req
                                  select_type="street" use_parent_id
                                  :disabled="!cityId"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" filled label="№ будинку" v-model="buildingNumber"
                          hide-details
                          :disabled="!streetId"
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="buildingNumber ? '' : 'req-star'"
                          color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select :items="buildingTypes" filled label="Вид будинку"
                      hide-details
                      v-model="buildingType" :disabled="!buildingNumber"
                      required :rules="[v => !!v || 'Це поле є обов’язковим']"
                      :class="buildingType ? '' : 'req-star'"
                      color="grey"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Початковий особовий"
                          v-model="buildPersonIdStart"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Кінцевий особовий"
                          v-model="buildPersonIdEnd"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Порядковий номер будинку"
                          v-model="sequenceNumber"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field type="text" hide-details filled label="Клас будинку"
                          v-model="buildClass"
                          color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" @click.stop="createBuilding" :loading="getModalLoading"
             class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {buildingTypes_select} from "@/utils/icons";
import {CREATE_BUILDING, DELETE_BUILDING, UPDATE_BUILDING} from "@/store/actions/building";
import {mapGetters} from 'vuex'
import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'building_modal_delete'

export default {
  name: "HWP_Modal_Building",
  mixins: [ModalComponentMixin],
  components: {
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
  },
  data() {
    return {
      buildingTypes: buildingTypes_select,
      buildingId: this.item.id,
      buildingType: this.item.building_type || '',
      buildingAddress: this.item.address || '',
      buildingNumber: this.item.build_number || '',
      buildClass: this.item.build_class || 0,
      sequenceNumber: this.item.sequence_number || 0,
      buildPersonIdStart: this.item.person_id_start || 0,
      buildPersonIdEnd: this.item.person_id_end || 0,
      streetId: this.item.street_id || '',
      cityId: this.item.city_id || '',
    }
  },
  methods: {
    closeModal(payload=null) {
      this.$emit('closeModal', payload)
      this.buildingId = this.item.id
      this.buildingType = this.item.building_type
      this.buildingAddress = this.item.address
      this.buildingNumber = this.item.build_number
      this.buildPersonIdStart = this.item.person_id_start
      this.buildPersonIdEnd = this.item.person_id_end
      this.buildClass = this.item.build_class
      this.streetId = this.item.street_id
      this.cityId = this.item.city_id
      this.sequenceNumber = this.item.sequence_number

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення будинку за адресою: ${this.buildingAddress}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    createBuilding() {
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: 'Населений пункт, Вулиця, № будинку та вид будинку - мають бути заповнені',
          color: 'error lighten-1'
        })
        return
      }
      const payload = {
        building_type: this.buildingType,
        build_number: this.buildingNumber,
        person_id_start: this.buildPersonIdStart,
        person_id_end: this.buildPersonIdEnd,
        build_class: this.buildClass,
        street_id: this.streetId,
        city_id: this.cityId,
        sequence_number: this.sequenceNumber
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_BUILDING, payload)
          .then(res => {
            if (res) {
              this.closeModal({fetch: true, emit: true})
            }
          })
      } else {
        payload.id = this.buildingId
        this.$store.dispatch(UPDATE_BUILDING, payload)
            .then(res => {
              if (res) {
                this.closeModal({fetch: true, emit: true})
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.buildingId = payload.id
              this.buildingType = payload.building_type
              this.buildingAddress = payload.address
              this.buildingNumber = payload.build_number
              this.buildPersonIdStart = payload.person_id_start
              this.buildPersonIdEnd = payload.person_id_end
              this.buildClass = payload.build_class
              this.streetId = payload.street_id
              this.cityId = payload.city_id
              this.sequenceNumber = payload.sequence_number
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_BUILDING, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({fetch: true, emit: true})
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      status: 'getBuildingStatus',
    }),
  }
}
</script>
